import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const mainNetChainId = 56
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[mainNetChainId]
}

export const getFlavorsAddress = () => { return getAddress(addresses.Flavors) }
export const getCreameryAddress = () => { return getAddress(addresses.Creamery) }
export const getCreameryV2Address = () => { return getAddress(addresses.CreameryV2) }
export const getFlavorSwitcher2Address = () => { return getAddress(addresses.FlavorSwitcher2) }

export const getFlavorBridgeAddress = () => { return getAddress(addresses.FlavorBridge) }
export const getFlavorDripper0Address = () => { return getAddress(addresses.FlavorDripper0) }
export const getFlavorDripper1Address = () => { return getAddress(addresses.FlavorDripper1) }
export const getOwnableFlavorsAddress = () => { return getAddress(addresses.OwnableFlavors) }
export const getFlavorsChainDataAddress = () => { return getAddress(addresses.FlavorsChainData) }
export const getFlavorsDripSqueezerAddress = () => { return getAddress(addresses.FlavorsDripSqueezer) }
export const getFLVPairAddress = () => { return getAddress(addresses.FlvPair) }

export const getTEST_CreameryAddress = () => { return getAddress(addresses.TEST_Creamery) }
export const getTEST_FlavorDripper0Address = () => { return getAddress(addresses.TEST_FlavorDripper0) }
export const getTEST_FlavorDripper1Address = () => { return getAddress(addresses.TEST_FlavorDripper1) }
export const getTEST_FlavorsAddress = () => { return getAddress(addresses.TEST_Flavors) }
export const getTEST_OwnableFlavorsAddress = () => { return getAddress(addresses.TEST_OwnableFlavors) }
export const getTEST_FlavorSwitcher2Address = () => { return getAddress(addresses.TEST_FlavorSwitcher2) }
export const getTEST_CreameryV2Address = () => { return getAddress(addresses.TEST_CreameryV2) }
export const getTEST_FlvPairAddress = () => { return getAddress(addresses.TEST_FlvPair) }


export const getFLVAddress = () => { return getAddress(tokens.flv.address) }
export const getNSMAddress = () => { return getAddress(tokens.nsm.address) }
export const getNSMPairAddress = () => { return getAddress(addresses.nsmPair) }
export const getMooneyAddress = () => { return getAddress(tokens.Mooney.address) }
export const getNSMMigrateAddress = () => { return getAddress(addresses.nsmMigrate) }
export const getPresaleFLVAddress = () => { return getAddress(addresses.PresaleFLV) }
export const getSAFEEARNAddress = () => { return getAddress(tokens.SafeEarn.address) }
export const getPublicPresaleFLVAddress = () => { return getAddress(addresses.publicPresaleFLV) }

export const getWBNBAddress = () => { return getAddress(addresses.WBNB) }
export const getWETHAddress = () => { return getAddress(addresses.WETH) }
export const getPancakeRouterAddress = () => { return getAddress(addresses.PancakeRouter) }
export const getNotToolsLPDataV3Address = () => { return getAddress(addresses.NotToolsLPDataV3) }
export const getPancakeFactoryV2Address = () => { return getAddress(addresses.PancakeFactoryV2) }
export const getNotToolsERC20DataAddress = () => { return getAddress(addresses.NotToolsERC20Data) }
export const getNotToolsMultiSendAddress = () => { return getAddress(addresses.NotToolsMultiSend) }
export const getNotToolsBalancesV2Address = () => { return getAddress(addresses.NotToolsBalancesV2) }
export const getNotToolsLivePriceV3Address = () => { return getAddress(addresses.NotToolsLivePriceV3) }
export const getNotToolsFactoryCreate2Address = () => { return getAddress(addresses.NotToolsFactoryCreate2) }

export const getCakeAddress = () => { return getAddress(tokens.cake.address) }
export const getWbnbAddress = () => { return getAddress(tokens.wbnb.address) }
export const getLotteryAddress = () => { return getAddress(addresses.lottery) }
export const getMulticallAddress = () => { return getAddress(addresses.mulltiCall) }
export const getEasterNftAddress = () => { return getAddress(addresses.easterNft) }
export const getMasterChefAddress = () => { return getAddress(addresses.masterChef) }
export const getClaimRefundAddress = () => { return getAddress(addresses.claimRefund) }
export const getBunnyFactoryAddress = () => { return getAddress(addresses.bunnyFactory) }
export const getBunnySpecialAddress = () => { return getAddress(addresses.bunnySpecial) }
export const getLotteryTicketAddress = () => { return getAddress(addresses.lotteryNFT) }
export const getPancakeRabbitsAddress = () => { return getAddress(addresses.pancakeRabbits) }
export const getPointCenterIfoAddress = () => { return getAddress(addresses.pointCenterIfo) }
export const getPancakeProfileAddress = () => { return getAddress(addresses.pancakeProfile) }
export const getTradingCompetitionAddress = () => { return getAddress(addresses.tradingCompetition) }
