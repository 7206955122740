import random from 'lodash/random'

// Array of available nodes to connect to
export const nodes = [
  process.env.REACT_APP_NODE_1,
  process.env.REACT_APP_NODE_2,
  process.env.REACT_APP_NODE_3,
  process.env.REACT_APP_NODE_4,
  //process.env.REACT_APP_NODE_5,
  //process.env.REACT_APP_NODE_6,
  //process.env.REACT_APP_NODE_7,
  //process.env.REACT_APP_NODE_8,
  process.env.REACT_APP_NODE_9,
  process.env.REACT_APP_NODE_10,
  process.env.REACT_APP_NODE_11,
  process.env.REACT_APP_NODE_12,
]

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getNodeUrl
