import { TranslatableText } from 'state/types'
export type IfoStatus = 'idle' | 'coming_soon' | 'live' | 'finished'
export type PresaleStatus = 'idle' | 'coming_soon' | 'live' | 'finished'


export interface Drip {
  id: string
  isActive: boolean
  address: string
  //lpAddress: string
  name: string
  subTitle: string
  description: string
  //logo: string
  tokenDecimals: number
  tokenSymbol: string
  projectSiteUrl: string
  //dripStartBlock: number

  // pancake swap crap, remove later, breaks without it for now
  launchDate: string
  launchTime: string
  saleAmount: string
  raiseAmount: string
  cakeToBurn: string
  //projectSiteUrl: string
  currency: string
  currencyAddress: string
  releaseBlockNumber: number
  campaignId?: string
  // pancake swap crap, remove later, breaks without it for now
}


export interface Presale {
  id: string
  isActive: boolean
  presaleAddress: string
  name: string
  symbol: string
  decimals: number
  subTitle: string
  description: string
  hardCap:  number
  softCap:  number
  totalContributions: number
  presaleStartTimestamp: number
  presaleEndTimestamp: number
  tokenLaunchTimestamp: number
  claimsEnabledOnBlockNumber: number
  globalTotal_contributions: number
  globalTotal_claims: number
  tokensPerNativeCoin: number
  claimsEnabled: boolean
  contributionsEnabled: boolean
  minimumContribution: number
  maximumContribution: number
}

export interface Ifo {
  id: string
  isActive: boolean
  address: string
  name: string
  subTitle?: string
  description?: string
  launchDate: string
  launchTime: string
  saleAmount: string
  raiseAmount: string
  cakeToBurn: string
  projectSiteUrl: string
  currency: string
  currencyAddress: string
  tokenDecimals: number
  tokenSymbol: string
  releaseBlockNumber: number
  campaignId?: string
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
}

export interface Address {
  97?: string
  56: string
}

export interface Token {
  symbol: string
  address?: Address
  decimals?: number
  projectLink?: string
}

export interface FarmConfig {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  token: Token
  quoteToken: Token
  multiplier?: string
  isCommunity?: boolean
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
}

export interface PoolConfig {
  sousId: number
  earningToken: Token
  stakingToken: Token
  stakingLimit?: number
  contractAddress: Address
  poolCategory: PoolCategory
  tokenPerBlock: string
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}

export type NftImages = {
  blur?: string
} & Images

export type NftVideo = {
  webm: string
  mp4: string
}

export type Nft = {
  name: string
  description: string
  images: NftImages
  sortOrder: number
  bunnyId: number
  video?: NftVideo
}

export type TeamImages = {
  alt: string
} & Images

export type Team = {
  id: number
  name: string
  description: string
  isJoinable?: boolean
  users: number
  points: number
  images: TeamImages
  background: string
  textColor: string
}

export type CampaignType = 'ifo'

export type Campaign = {
  id: string
  type: CampaignType
  title?: TranslatableText
  description?: TranslatableText
  badge?: string
}

export type PageMeta = {
  title: string
  description: string
  image: string
}
