import { Menu as UikitMenu } from '@pancakeswap-libs/uikit';
import { useWeb3React } from '@web3-react/core';
import { allLanguages } from 'config/localisation/languageCodes';
import { LanguageContext } from 'contexts/Localisation/languageContext';
import useAuth from 'hooks/useAuth';
import useTheme from 'hooks/useTheme';
import React, { useContext } from 'react';
import { usePriceCakeBusd, useProfile } from 'state/hooks';
import config from './config';

const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const { profile } = useProfile()

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config}
      profile={{
        username: profile?.username,
        image: profile?.nft ? {/* }`/images/nfts/${profile.nft?.images.sm}` */} : undefined,
        profileLink: '/profile',
        noProfileLink: '/profile',
        showPip: !profile?.username,
      }}
      {...props}
    />
  )
}

export default Menu
