

export default {
  
  TEST_Creamery:              {56: '0x8494C1180E58C6E839C2bECE3A54424AdedDEfBb'},
  TEST_FlavorDripper0:        {56: '0x21086ef7559a507BED67824e21D4a1F5831Eb86d'},
  TEST_FlavorDripper1:        {56: '0xef86AF90fb1AB3C55f5e88D7b2486bbF4Cd43203'},
  TEST_Flavors:               {56: '0xA907AF2f8f89b6aFFce2b79b76D529ce31515c84'},
  TEST_OwnableFlavors:        {56: '0xb87032eA6E85FA89Eef2Aa07307f58CE7B29297B'},
  TEST_CreameryV2:            {56: '0xb6068e6a100850a9e4e21480ac951cbe727dcae0'},
  TEST_FlavorSwitcher2:       {56: '0x91a6771CB36858bb578f6fd27F0b61C25942073d'},
  TEST_FlvPair:               {56: '0xF0d83d22bFb3818E75DA63e29310F817C40141aD'},

  
  //ENABLE THIS SECTION FOR templateSettings, DISABLE THE SECTION BELOW
  //OwnableFlavors:         {56: '0x8494C1180E58C6E839C2bECE3A54424AdedDEfBb'},
  //FlavorDripper0:         {56: '0x21086ef7559a507BED67824e21D4a1F5831Eb86d'},
  //FlavorDripper1:         {56: '0xef86AF90fb1AB3C55f5e88D7b2486bbF4Cd43203'},
  //Flavors:                {56: '0xA907AF2f8f89b6aFFce2b79b76D529ce31515c84'},
  //Creamery:               {56: '0xb87032eA6E85FA89Eef2Aa07307f58CE7B29297B'},
  //FlavorSwitcher2:        {56: '0xb6068e6a100850a9e4e21480ac951cbe727dcae0'},
  //CreameryV2:             {56: '0x91a6771CB36858bb578f6fd27F0b61C25942073d'},
  //FlvPair:                {56: '0xF0d83d22bFb3818E75DA63e29310F817C40141aD'},
  //ENABLE THIS SECTION FOR TESTING, DISABLE THE SECTION BELOW
  
  
  
  //DISABLE THIS SECTION WHEN TESTING ENABLE THE SECTION ABOVE
  OwnableFlavors:         {56: '0xF1a4592820E0141946D4a3fC159F87Ba06c0a809', 97: '0xF1a4592820E0141946D4a3fC159F87Ba06c0a809'},
  FlavorDripper0:         {56: '0xF1A45E8d1Eff4fb030e6E89566e82E15223F3469', 97: '0xF1A45E8d1Eff4fb030e6E89566e82E15223F3469'},
  FlavorDripper1:         {56: '0xF1A45d53c9Aa16c530a5cb020A4A32DeD8c733AE', 97: '0xF1A45d53c9Aa16c530a5cb020A4A32DeD8c733AE'},
  Flavors:                {56: '0xF1a45E52d6d11D0e591d4F18DA9D88a4c266b300', 97: '0xF1a45E52d6d11D0e591d4F18DA9D88a4c266b300'},
  Creamery:               {56: '0xF1a45Caeab6b2A317EC417E97b032BEa1c086Cb8', 97: '0xF1a45Caeab6b2A317EC417E97b032BEa1c086Cb8'},
  FlavorSwitcher2:        {56: '0xf1a455526f891ebE176709De2fbFde4EC0Df8Eb0'},
  CreameryV2:             {56: '0xF1a452CCB171c81D36740f4f5FeA30B9E793fB22'},
  FlvPair:                {56: '0x01d856456be2b57d82c70cd31dd1dec69fe0b475'},
  //DISABLE THIS SECTION WHEN TESTING ENABLE THE SECTION ABOVE
  

  FlavorsFactoryFactory:  {56: '0x40537d0540ff4C0F3fbE9f12570Ce4f1B7dc1D3F', 97: '0x40537d0540ff4C0F3fbE9f12570Ce4f1B7dc1D3F'},
  FlavorsFactory:         {56: '0xF1a4Fac7E5B296a65Ae19b581E20eb58c6a29246', 97: '0xF1a4Fac7E5B296a65Ae19b581E20eb58c6a29246'},
  FlavorsFactoryWallet:   {56: '0xf1a45Fac9A879242BcE8A2837e6C90F5088c519E', 97: '0xf1a45Fac9A879242BcE8A2837e6C90F5088c519E'},
  IceCreamMan:            {56: '0xf1a455e6b5BA2303E37b81B258AF9D0110bCd700', 97: '0xf1a455e6b5BA2303E37b81B258AF9D0110bCd700'},
  FlavorsDripSqueezer:    {56: '0xF1a455C756731333C1621Da43764CF6c328fc431', 97: '0xF1a455C756731333C1621Da43764CF6c328fc431'},
  FlavorBridge:           {56: '0xF1a45afb3b4f6196866B364784b39e855a355b83', 97: '0xF1a45afb3b4f6196866B364784b39e855a355b83'},
  FlavorsChainData:       {56: '0xF1A45C17d8E7B1896f95BEeEFA989aC9E42DC100', 97: '0xF1A45C17d8E7B1896f95BEeEFA989aC9E42DC100'},

  publicPresaleFLV:       {56: '0xF1A45556a43451E0B23d70b913FdaEd862165cAA', 97: '0xF1A45556a43451E0B23d70b913FdaEd862165cAA'},
  FLV:                    {56: '0xF1a45E52d6d11D0e591d4F18DA9D88a4c266b300', 97: '0xF1a45E52d6d11D0e591d4F18DA9D88a4c266b300'},
  NSM:                    {56: '0x337e35Ed5B38D5C7Ec9F8d7cF78fe7F43d7DEC6F', 97: '0x6746767c6dF2A2bF497b4DfBbB1b0767b9946786'},
  WBNB:                   {56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd'},
  WETH:                   {56: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'},
  PresaleFLV:             {56: '0x78fDf2F1985118943FF8fc7d4d9702D9820d7C9D'},
  nsmPair:                {56: '0x357f9cd8f2749A31119C3E32729965CA56f4cBd8', 97: '0x73BEDe1D162c3DCD38b5a44c5637ae66D5f11785'},
  nsmMigrate:             {56: '0xC63a76B9Dc7dbad4a786969a13613a35466cA9a0', 97: '0xC63a76B9Dc7dbad4a786969a13613a35466cA9a0'},

  NotToolsLPDataV3:       {56: '0xfffff8fE58810B693DA405bEfab496E64EC1E40E'},
  NotToolsERC20Data:      {56: '0xD00DAD8e9680d863bf178fC74F6F58deA1bcc34b'},
  NotToolsMultiSend:      {56: '0x7117113772b3e95BeFAC2d5a183e60070465CE95'},
  NotToolsBalancesV2:     {56: '0xaaaa1D1Ba4572B419584e2C30a357A890172B32C'},
  NotToolsLivePriceV3:    {56: '0x4444434a5917ae1c00FE51705a8C9C4B36887cde'},
  NotToolsFactoryCreate2: {56: '0x04396f9531c5f3cd0fac735f661a987e7e08fc87'},

  SafeEarn:               {56: '0x099f551eA3cb85707cAc6ac507cBc36C96eC64Ff'},
  Mooney:                 {56: '0x98631c69602083d04f83934576a53e2a133d482f'},
  Busd:                   {56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'},
  Cake:                   {56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82'},

  PancakeFactoryV2:       {56: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'},
  PancakeRouter:          {56: '0x10ed43c718714eb63d5aa57b78b54704e256024e', 97: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3'},

  // THE ORIGINAL PANCAKE SWAP ADDRESSES //
  claimRefund:        {56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149'},
  masterChef:         {56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E', 97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9'},
  sousChef:           {56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95', 97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a'},
  lottery:            {56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91', 97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f'},
  lotteryNFT:         {56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1', 97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e'},
  mulltiCall:         {56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb', 97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412'},
  pancakeProfile:     {56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a', 97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A'},
  pancakeRabbits:     {56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07', 97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E'},
  bunnyFactory:       {56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf', 97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148'},
  pointCenterIfo:     {56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a', 97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3'},
  bunnySpecial:       {56: '0xFee8A195570a18461146F401d6033f5ab3380849', 97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C'},
  tradingCompetition: {56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA', 97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97'},
  easterNft:          {56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066', 97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F'},
};
