import { Ifo } from './types'

// THIS PAGE IS FOR THE PRESALES
// THIS PAGE IS FOR THE PRESALES
// THIS PAGE IS FOR THE PRESALES
// THIS PAGE IS FOR THE PRESALES
// THIS PAGE IS FOR THE PRESALES
// THIS PAGE IS FOR THE PRESALES
const ifos: Ifo[] = [
  
  {
    id: 'flavors',
    address: '0xF1a45E52d6d11D0e591d4F18DA9D88a4c266b300',
    isActive: true,
    name: 'Flavors BSC',
    subTitle: 'Scoop up access to a flavorful variety of vetted projects and exclusive presales! Earn rewards through every melty drip.',
    description: 'Flavors BSC is here for the community. Grab a taste out of what it means to get more from DeFi. Scoop up access to a flavorful variety of vetted projects and exclusive presales and earn rewards through every melty drip. Invest in something delicious.',
    launchDate: 'October 22nd',
    launchTime: '7PM PST',
    saleAmount: '60,000,000 FLV',
    raiseAmount: '600 BNB',
    cakeToBurn: '',
    projectSiteUrl: 'https://flavorsBSC.com',
    currency: '',
    currencyAddress: '',
    tokenDecimals: 9,
    tokenSymbol: 'FLV',
    releaseBlockNumber: 0,
    campaignId: '0',
  },
]

export default ifos
